<template>
  <!-- <q-tabs
    v-model="tab"
    class="text-grey"
    active-color="primary"
    indicator-color="primary"
    vertical
    align="justify"
  >
    <q-tab name="layers" :label="$t('label.layers')" />
    <q-tab name="images" :label="$t('label.files')" />
    <q-tab name="projects" :label="$t('label.projects')" />
  </q-tabs>

  <q-tab-panels v-model="tab" animated>
    <q-tab-panel name="layers" class="q-px-none">
      <LayersList />
    </q-tab-panel>

    <q-tab-panel name="images" class="q-px-none">
      <q-list bordered separator class="scroll-area">
        <template v-for="image in uploadedImages" :key="image.id">
          <q-item @click="addToLayers(image.url)" clickable v-ripple>
            <q-item-section avatar>
              <q-img :src="image.url" style="height: 34px; max-width: 34px" />
            </q-item-section>

            <q-item-section top>
              <q-item-label class="q-mt-sm">{{
                $t("label.image")
              }}</q-item-label>
            </q-item-section>

            <q-item-section avatar side>
              <div class="text-grey-8 q-gutter-xs">
                <q-btn
                  class="text-red"
                  size="12px"
                  flat
                  dense
                  round
                  icon="delete"
                  @click.stop="removeUploadedImage(image.id)"
                />
                <q-btn size="12px" flat dense round icon="add" />
              </div>
            </q-item-section>
          </q-item>
        </template>
        <q-separator v-show="uploadedImages.length" />
      </q-list>
    </q-tab-panel>

    <q-tab-panel name="projects" class="q-px-none">
      <ProjectsTab />
    </q-tab-panel>
  </q-tab-panels> -->
  <q-splitter v-model="splitterModel">
    <template v-slot:before>
      <q-tabs v-model="tab" vertical class="text-teal">
        <q-tab name="colors" icon="palette" label="color" />
        <q-tab name="image" icon="image" label="Updload" />
        <q-tab name="text" icon="title" label="Text" />
      </q-tabs>
    </template>

    <template v-slot:after>
      <q-tab-panels
        v-model="tab"
        animated
        swipeable
        vertical
        transition-prev="jump-up"
        transition-next="jump-up"
      >
        <q-tab-panel name="colors">
          <colors-variant />
        </q-tab-panel>
        <q-tab-panel name="image">
          <LayersMedia />
        </q-tab-panel>
        <q-tab-panel name="text">
          <LayersText />
        </q-tab-panel>
      </q-tab-panels>
    </template>
  </q-splitter>
</template>

<script>
import { mapState } from "vuex";
import { mapMutations } from "vuex";

import CanvasService from "@/services/canvas";
import LayersText from "@/components/Layers/text";
import ColorsVariant from "@/components/Layers/colors";
import LayersMedia from "@/components/Layers/media";
// import ProjectsTab from "@/components/Projects";

export default {
  name: "LayersPanel",

  components: {
    LayersMedia,
    LayersText,
    ColorsVariant,
    // ProjectsTab,
  },

  data: () => ({
    tab: "layers",
  }),

  computed: {
    ...mapState("app", ["uploadedImages"]),
  },

  methods: {
    ...mapMutations("app", ["removeUploadedImage"]),

    addToLayers(url) {
      CanvasService.addImageLayer(url);
      this.tab = "layers";
    },
  },
};
</script>
