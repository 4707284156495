import axios from "axios";
import { store } from "@/store";

let API_URL;
if (location.hostname == "pod.speedliv.com") {
  API_URL = "https://api-pod.speedliv.com/api";
} else if (location.hostname == "pod.codinafrica.com") {
  API_URL = "https://api-pod.codinafrica.com/api";
} else {
  API_URL = "http://" + location.hostname + ":3021/api";
  //API_URL = "http://167.71.1.17:3021/api";
}
const axiosInstance = axios.create({
  baseURL: API_URL,
});

axiosInstance.interceptors.request.use(
  (config) => {
    const accessToken = store.state.app.token;
    if (accessToken) {
      config.headers["x-auth-token"] = `${accessToken}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

export default axiosInstance;
