<template>
  <q-card class="q-mx-2" v-if="selectedModel">
    <q-card-section>
      <p class="text-subtitle1">{{ $t("label.color") }}</p>
      <q-card-actions>
        <div
          v-for="variant in selectedModel.variants"
          :key="variant.images.front.url"
          @click="setSelectedModelColor(variant.images)"
          class="cursor-pointer model-colors"
        >
          <img :src="variant.images.front.url" />
        </div>
      </q-card-actions>
    </q-card-section>
  </q-card>
</template>

<script>
import { mapState } from "vuex";
import { mapMutations } from "vuex";

export default {
  name: "ColorsVariant",

  data: () => ({
    orderModal: false,
    orderCreatedModal: false,
    loading: false,
    phone: "",
    email: "",
  }),
  watch: {},
  mounted() {
    console.log("------ColorsVariant------");
  },
  computed: {
    ...mapState("product", ["selectedModel", "selectedModelColor"]),
    ...mapState("canvas", ["layers"]),
  },

  methods: {
    ...mapMutations("product", ["setSelectedModelColor"]),
  },
};
</script>

<style lang="scss">
.model-colors {
  position: relative;
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  overflow: hidden;
  margin-right: 5px;
  margin-bottom: 5px;
  border: 1px solid #ccc;

  img {
    width: 110%;
    height: 110%;
    object-fit: none;
  }

  &.selected-model {
    i {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #000;
      font-weight: bold;
      background: #ffffff82;
    }
  }
}
</style>
