<template>
  <div class="q-pb-md q-px-2">
    <q-btn
      no-caps
      @click="addTextLayer"
      color="primary"
      icon="add"
      label="Add your text"
      class="q-mr-sm"
      style="border-radius: 7px; width: 100%"
    />
  </div>
  <q-list class="scroll-area">
    <template v-for="layer in layers" :key="layer.layerId">
      <LayerItem :layer="layer" v-if="layer.mode == mode" />
      <q-separator style="margin-bottom: 12px" v-show="layers.length" />
    </template>
    <LoadingItem v-if="loadingLayer" />
  </q-list>
</template>

<script>
import { mapState } from "vuex";
import { mapMutations } from "vuex";

import CanvasService from "@/services/canvas";

import LayerItem from "@/components/Layers/Item";
import LoadingItem from "@/components/Layers/Loading";

export default {
  name: "LayersList",

  components: {
    LayerItem,
    LoadingItem,
  },

  data: () => ({
    tab: "layers",
    loadingLayer: false,
  }),

  computed: {
    ...mapState("canvas", ["layers", "mode"]),
  },

  methods: {
    ...mapMutations("app", ["pushUploadedImage"]),

    addTextLayer() {
      CanvasService.addTextLayer();
    },
  },
};
</script>

<style lang="scss">
.scroll-area {
  @media (max-width: $breakpoint-xs) {
    min-height: 100px;
  }
}
</style>
