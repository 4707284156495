export default {
  namespaced: true,

  state() {
    return {
      pricing: {},
      price: 0,
      sizes: {
        xs: 0,
        s: 0,
        m: 0,
        l: 0,
        xl: 0,
        xxl: 0,
      },
    };
  },

  mutations: {
    setPrice(state, payload) {
      state.price = payload;
    },
    setPricing(state, payload) {
      state.pricing = payload;
    },
  },

  getters: {
    quantity() {
      return 1;
    },
  },

  actions: {
    calculatePrice() {
      return;
    },
  },
};
