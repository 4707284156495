<template>
  <div class="stiky flex">
    <q-btn
      @click="openSaveModal"
      no-caps
      color="primary"
      label="Save Desing"
      class="q-mr-xl btn_save"
      style="border-radius: 7px; float: right; height: 35px"
    />
  </div>
  <q-dialog v-model="newProjectModal" persistent>
    <q-card style="min-width: 350px">
      <q-card-section class="row items-center">
        <span class="q-ml-sm">
          {{ $t("text.projectName") }}
        </span>
      </q-card-section>
      <q-card-section class="q-pt-none">
        <q-input dense v-model="title" autofocus @keyup.enter="saveAs" />
      </q-card-section>
      <q-card-actions align="right">
        <q-btn flat :label="$t('label.cancel')" color="primary" v-close-popup />
        <q-btn
          flat
          :label="$t('label.save')"
          color="primary"
          v-close-popup
          @click="saveAs"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import { mapState } from "vuex";
import { mapMutations } from "vuex";

import ProjectService from "@/services/project";
import CanvasService from "@/services/canvas";

export default {
  name: "SaveStiky",

  data: () => ({
    deletableId: null,
    confirmModal: false,
    newProjectModal: false,

    title: "",
  }),

  computed: {
    ...mapState("canvas", ["mode"]),
    ...mapState("app", ["projects", "projectsFetched", "selectedProjectId"]),
    ...mapState("product", ["selectedModel", "selectedModelColor"]),
  },

  methods: {
    ...mapMutations("canvas", ["setMode"]),
    ...mapMutations("app", [
      "setProjects",
      "pushProject",
      "deleteProject",
      "setSelectedProjectId",
      "setProjectsFetched",
    ]),
    ...mapMutations("product", [
      "selectFristModel",
      "setSelectedModel",
      "setSelectedModelColor",
    ]),

    openSaveModal() {
      this.newProjectModal = true;
    },

    async saveAs() {
      if (!this.title) {
        return;
      }
      let captures = await CanvasService.capture();

      let project = await ProjectService.create({
        title: this.title,
        mockupId: this.selectedModel._id,
        preview: {
          front: captures.front.src,
          back: captures.back.src,
        },
        canvas: this.getProjectData(),
      });
      console.log("fdçàà", this.selectedModel._id);

      if (project) {
        this.setSelectedProjectId(project.id);
        delete project.json;
        this.pushProject(project);
      }

      this.newProjectModal = false;
      this.title = "";
    },

    getProjectData() {
      return JSON.stringify({
        mode: this.mode,
        selectedModel: this.selectedModel,
        selectedModelColor: this.selectedModelColor,
        canvasData: CanvasService.toJSON(),
      });
    },

    //   async loadProjects() {
    //     let projects = await ProjectService.getProjects();
    //     if (projects) {
    //       this.setProjects(projects);
    //       this.setProjectsFetched(true);
    //     }
    //   },

    //   async launchProjects(id) {
    //     let project = await ProjectService.getProject(id);
    //     if (project) {
    //       let data = JSON.parse(project.json);
    //       CanvasService.loadFromJSON(data.canvasData, () => {
    //         this.setSelectedModel(data.selectedModel);
    //         this.setSelectedModelColor(data.selectedModelColor);
    //         this.setSelectedProjectId(id);
    //         this.setMode(data.mode);
    //       });
    //     }
    //   },

    //   exitProject() {
    //     this.setSelectedProjectId(null);
    //     this.selectFristModel();
    //     CanvasService.deleteAllLayers();
    //   },

    //   async confirmDelete(id) {
    //     this.confirmModal = true;
    //     this.deletableId = id;
    //   },

    //   async deleteProjectAsync() {
    //     let result = await ProjectService.delete(this.deletableId);
    //     if (result) {
    //       this.deleteProject(this.deletableId);

    //       if (this.selectedProjectId == this.deletableId) {
    //         this.exitProject();
    //       }
    //     }
    //   },
  },
};
</script>
<style>
.btn_save {
  font-size: medium !important;
  font-weight: 800 !important;
  margin-right: 70px !important;
  padding-inline: 35px !important;
  background-color: #24c7a4 !important;
}
.stiky {
  position: fixed;
  align-items: center;
  bottom: 0;
  display: block;
  width: 100%;
  justify-content: end;
  padding-inline: 12px;
  right: 0;
  left: 0;
  height: 54px;
  box-shadow: 0 0 5px #d9d9d9;
  background-color: #f5f5f5;
}
</style>
