<template>
  <div class="q-pb-md q-px-2">
    <q-btn
      no-caps
      @click="this.$refs.fileInput.click()"
      color="primary"
      icon="cloud_upload"
      class="w-full"
      style="border-radius: 7px; width: 100%"
      label=" Upload your design"
    />
    <input
      ref="fileInput"
      type="file"
      @change="uploadImage"
      accept="image/*"
      class="hidden"
    />
  </div>

  <q-list class="scroll-area">
    <template v-for="layer in layers" :key="layer.layerId">
      <LayerItem :layer="layer" v-if="layer.mode == mode" />
    </template>
    <LoadingItem v-if="loadingLayer" />
    <q-separator v-show="layers.length" />
  </q-list>
</template>

<script>
import { mapState } from "vuex";
import { mapMutations } from "vuex";

import CanvasService from "@/services/canvas";
import MediaService from "@/services/media";

import LayerItem from "@/components/Layers/Item";
import LoadingItem from "@/components/Layers/Loading";

export default {
  name: "LayersList",

  components: {
    LayerItem,
    LoadingItem,
  },

  data: () => ({
    tab: "layers",
    loadingLayer: false,
  }),

  computed: {
    ...mapState("canvas", ["layers", "mode"]),
  },

  methods: {
    ...mapMutations("app", ["pushUploadedImage"]),

    addImageLayer(url) {
      CanvasService.addImageLayer(url);
    },

    createBase64Image(fileObjet) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(fileObjet);
        reader.onloadend = () => resolve(reader.result); // Only get the base64 part
        reader.onerror = reject;
      });
    },
    async uploadImage(e) {
      let file = e.target.files[0];
      if (!file) {
        return;
      }
      const base64 = await this.createBase64Image(file);

      this.loadingLayer = true;

      const image = await MediaService.uploadbase64(base64, true);
      console.log(image);

      if (image) {
        this.addImageLayer(image.src);
        this.pushUploadedImage({
          id: Date.now(),
          url: image.src,
        });
      }
      this.loadingLayer = false;
    },
  },
};
</script>

<style lang="scss">
.scroll-area {
  @media (max-width: $breakpoint-xs) {
    min-height: 100px;
  }
}
</style>
