import axios from "@/services/axios";
import Notify from 'quasar/src/plugins/notify/Notify.js';;
import { t } from "../locales";

const MediaService = {
  async upload(data) {
    try {
      let result = await axios.post("http://localhost:3050/api/media", data, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      return result.data.doc;
    } catch (e) {
      Notify.create({ type: "error", message: t("text.error.cantUploadFile") });
      return false;
    }
  },
  async uploadbase64(base64, sticker = false) {
    let params = {};
    if (sticker) params["sticker"] = true;
    try {
      let result = await axios.post(
        "/images/create",
        {
          src: base64,
        },
        { params }
      );
      return result.data;
    } catch (e) {
      Notify.create({ type: "error", message: t("text.error.cantUploadFile") });
      return false;
    }
  },
  async uploadBlob(blob) {
    try {
      let data = new FormData();
      data.append("file", blob, "capture.png");
      // const data = await this.$server.create("medias",{ src: reader.result } ); base64
      let result = await axios.post("http://localhost:3050/api/media", data, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      return result.data.doc;
    } catch (e) {
      Notify.create({ type: "error", message: t("text.error.cantUploadFile") });
      return false;
    }
  },
};

export default MediaService;
