<template>
  <q-layout view="lHh Lpr lFf">
    <q-page-container>
      <q-page padding>
        <div class="row">
          <div class="col-md-3 col-sm-12 sm-full-width">
            <LayersPanel />
          </div>
          <div class="col-md-6 col-sm-12 canvas-column">
            <div class="canvas-designer">
              <ModeSelector />
              <div class="flex flex-center canvas-wrapper">
                <canvas ref="canvas"></canvas>
              </div>
            </div>
          </div>
          <div class="col-md-3 col-sm-12 order-sm-last sm-full-width">
            <ControlsPanel />
          </div>
        </div>
        <SaveStiky />
      </q-page>
    </q-page-container>
  </q-layout>

  <InitModal />
  <AuthModal />
  <CatalogModal />
  <CustomProductModal />
</template>

<script>
import { mapState } from "vuex";
import { mapGetters } from "vuex";
import { mapMutations } from "vuex";
import { fabric } from "fabric";
import { syncLanguage } from "./locales";

import UserService from "@/services/user";
import CanvasService from "@/services/canvas";
import ProductService from "@/services/product";

import WebFont from "webfontloader";
import WebFontConfig from "@/WebFontConfig";

import LayersPanel from "@/components/Layers/Panel";
import ModeSelector from "@/components/Controls/ModeSelector";
import ControlsPanel from "@/components/Controls/Panel";

import InitModal from "@/components/Modals/Init";
import AuthModal from "@/components/Modals/Auth";
import CatalogModal from "@/components/Modals/Catalog";
import CustomProductModal from "@/components/Modals/CustomProduct";
import SaveStiky from "./components/SaveStiky.vue";
import ProjectService from "./services/project";

export default {
  name: "LayoutDefault",

  inject: ["ctx"],

  components: {
    LayersPanel,
    ControlsPanel,
    ModeSelector,
    SaveStiky,
    InitModal,
    AuthModal,
    CatalogModal,
    CustomProductModal,
  },
  data() {
    return {
      id: null,
      type: "create",
    };
  },
  created() {
    const params = new URLSearchParams(window.location.search);
    this.id = params.get("id");
    if (params.get("type")) {
      this.type = params.get("type");
    }
    this.setToken(params.get("token"));
  },
  mounted() {
    if (this.id && this.type === "create") {
      this.loadProductModel(this.id);
    } else if (this.id && this.type === "edit") {
      this.launchProjects(this.id);
    }
    const ref = this.$refs.canvas;
    this.ctx.canvas = new fabric.Canvas(ref, {
      selection: false,
      width: 600,
      height: 560,
      allowTouchScrolling: true,
    });
    // let center = this.ctx.canvas.getCenter();

    const designArea = new fabric.Rect({
      left: this.ctx.canvas.width / 2, // center horizontally
      top: this.ctx.canvas.height / 2,
      originX: "center",
      originY: "center",
      width: 250,
      height: 200,
      fill: "#5c92f347",
      stroke: "#5c92f3",
      strokeWidth: 1,
      selectable: false,
    });
    this.ctx.canvas.add(designArea);
    this.ctx.canvas.on("before:selection:cleared", () => {
      this.resetSelectedLayer();
      this.setControlTab("object");
    });

    this.ctx.canvas.on("selection:created", (event) => {
      this.handleSelection(event);
    });

    this.ctx.canvas.on("selection:updated", (event) => {
      this.handleSelection(event);
    });

    this.ctx.canvas.on("object:modified", (event) => {
      if (event.action === "scale" || event.action === "rotate") {
        this.selectedLayer.dirty = true;
      }
    });
    this.ctx.canvas.on({
      "object:added": () => {
        this.syncLayers(this.ctx.canvas.getObjects());
      },
      "object:removed": () => this.syncLayers(this.ctx.canvas.getObjects()),
    });

    document.addEventListener("keydown", (e) => {
      if (
        ["Delete", "Backspace"].includes(e.key) &&
        this.selectedLayer.layerId
      ) {
        if (document.querySelector(".layer-text-field")?.matches(":focus"))
          return;
        CanvasService.removeLayer(this.selectedLayer);
      }
    });
    WebFont.load(WebFontConfig);
  },

  computed: {
    ...mapState("app", ["user"]),
    ...mapState("canvas", ["selectedLayer"]),
    ...mapState("product", ["selectedModelColor"]),
    ...mapGetters("product", ["selectedModelColorUrl"]),
  },

  watch: {
    // user(newUser) {
    //   this.loadProductModel(newUser?.id);
    // },
    selectedModelColorUrl(url) {
      if (url) CanvasService.drawSelectedModel();
    },
  },

  methods: {
    ...mapMutations("app", [
      "setUser",
      "setToken",
      "setSelectedProjectId",
      "setProjects",
      "setControlTab",
      "setAuthModalOpened",
      "setCatalogModalOpened",
    ]),
    ...mapMutations("order", ["setPricing"]),
    ...mapMutations("canvas", [
      "setMode",
      "syncLayers",
      "setSelectedLayer",
      "resetSelectedLayer",
    ]),
    ...mapMutations("product", [
      "setModels",
      "selectFristModel",
      "setSelectedModel",
      "setSelectedModelColor",
    ]),

    handleSelection(event) {
      let layer = event.selected[0];
      if (this.selectedLayer.layerId != layer.layerId) {
        this.setSelectedLayer(layer);
        this.setControlTab("object");
      }
    },
    async launchProjects(id) {
      let project = await ProjectService.getProject(id);
      if (project) {
        let data = JSON.parse(project.canvas);
        CanvasService.loadFromJSON(data.canvasData, () => {
          this.setSelectedModel(data.selectedModel);
          this.setSelectedModelColor(data.selectedModelColor);
          this.setSelectedProjectId(id);
          this.setModels(data);
          this.setMode(data.mode);
        });
      }
    },
    async loadProductModel(id) {
      let models = await ProductService.getModel(id);
      console.log("------------34", models);

      this.setModels(models);
    },

    async logout() {
      await UserService.logout();
      window.location.reload();
    },

    setLanguage(lang) {
      this.$i18n.locale = lang;
      syncLanguage(lang);
    },
  },
};
</script>

<style lang="scss">
.canvas-designer {
  box-shadow: 0 0 5px #d9d9d9;
  position: sticky;
  top: 55px;
  @media (min-width: $breakpoint-sm) {
    margin: 0 10px;
  }
  @media (max-width: $breakpoint-sm) {
    padding-top: 50px;
  }
}

.canvas-column {
  width: 100%;
  @media (max-width: $breakpoint-sm) {
    order: -1;
    margin-bottom: 30px;
  }
}

@media (max-width: $breakpoint-xs) {
  .canvas-wrapper {
    width: calc(100vw - 20px);
    overflow: hidden;
  }
  .canvas-container {
    position: relative;
    left: 50%;
    margin-left: -100%;
    margin-top: calc(25% * -0.7);
    margin-bottom: calc(30% * -0.7);
    transform: scale(0.7);
  }
}
</style>
